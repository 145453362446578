const SignInProcessor = ({setIsLoading,isLoading,setError}) => {
    // Get the API URL and API key from the environment variables
    const REGISTER_API_ENDPOINT = `${process.env.REACT_APP_API_BASE_URL}/login/`;
    const API_KEY = process.env.REACT_APP_API_KEY;
    
    const login = async (credentials) => {

        console.log(credentials)

        if (isLoading) return;

        setIsLoading(true); 

        try {
            const response = await fetch(REGISTER_API_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY,
                },
                body: JSON.stringify(credentials),
            });

            const data = await response.json();
  
            if (!data.token) {
                const key=Object.keys(data)[0];
                const error = new Error(data[key][0]);
                error.field = key;
                throw error;
            } else {
                
                return data; 
            }
        } catch (err) {

            setError(err); 
            return null; 
        } finally {
                setIsLoading(false);
        }
    };

    return login;
};

export default SignInProcessor;