
// File: src/components/LandingPage/DownloadSection.js
import React from 'react';
import QRCode from 'qrcode.react';

export default function DownloadSection() {
  return (
    <section className="py-12 bg-gray-900 text-center relative">
      <div className="absolute inset-0 bg-gradient-to-r from-gray-800 via-gray-900 to-black opacity-20 rounded-lg"></div>
      <div className="relative z-10 w-full px-4 sm:w-2/3 mx-auto">
        <div className="flex flex-col sm:flex-row items-center justify-center mb-6">
          <h2 className="text-2xl sm:text-3xl font-bold text-white mb-4 sm:mb-0 sm:mr-4 text-center">
            Ready to Elevate Your Professionalism?
          </h2>
          <div>
            <QRCode value="https://example.com/download" size={128} level="H" includeMargin={true} />
            <p className="text-gray-300 text-sm sm:text-base mt-2">Scan to Download</p>
          </div>
        </div>
      </div>
    </section>
  );
}
