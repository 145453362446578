import React, { useState } from 'react';

const Hero = () => {
  const [email, setEmail] = useState('');
  const [isSignIn, setIsSignIn] = useState(true);

  return (
    <section className="flex flex-col md:flex-row min-h-screen from-[#0E1117] to-[#111827] text-white">

      {/* Hero content */}
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center text-center p-4 md:p-8">
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-4">
          Elevate Your Professional Presence
        </h2>
        <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-semibold text-gray-300 mb-8">
          Minimize Mistakes, Save Time & Money. <br className="hidden sm:inline" />
          Showcase Your Work with a Personalized Portfolio.
        </p>
      </div>
      {/* Sign-in/Create account section */}
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center p-4 md:p-8">
        <div className="w-full max-w-md bg-gray-800 rounded-lg p-6 md:p-8">
          <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-6">
            {isSignIn ? "Sign In" : "Create Account"}
          </h2>
          <form className="space-y-4">
            <input
              type="email"
              placeholder="name@yourcompany.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full bg-gray-700 text-white p-2 rounded text-sm md:text-base"
            />
            <button 
              type="submit" 
              className="w-full bg-[#D97706] hover:bg-[#B45309] text-white p-2 rounded text-sm md:text-base"
            >
              {isSignIn ? "Continue with email" : "Create account"}
            </button>
            <div className="flex items-center justify-center space-x-2">
              <div className="flex-grow h-px bg-gray-600"></div>
              <span className="text-gray-400 text-sm">OR</span>
              <div className="flex-grow h-px bg-gray-600"></div>
            </div>
            <button 
              type="button"
              className="w-full bg-white text-black hover:bg-gray-200 p-2 rounded flex items-center justify-center text-sm md:text-base"
            >
              <svg className="w-4 h-4 md:w-5 md:h-5 mr-2" viewBox="0 0 24 24">
                <path fill="#4285F4" d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" />
                <path fill="#34A853" d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" />
                <path fill="#FBBC05" d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" />
                <path fill="#EA4335" d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" />
                <path fill="none" d="M1 1h22v22H1z" />
              </svg>
              Continue with Google
            </button>
          </form>
          <p className="mt-4 text-center text-xs md:text-sm text-gray-400">
            {isSignIn ? "Don't have an account?" : "Already have an account?"}
            <button
              type="button"
              onClick={() => setIsSignIn(!isSignIn)}
              className="ml-1 text-white hover:underline"
            >
              {isSignIn ? "Sign up" : "Sign in"}
            </button>
          </p>
        </div>
      </div>


    </section>
  );
};

export default Hero;