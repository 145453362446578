// File: src/components/LandingPage/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="bg-gray-900 py-4 text-center text-gray-400">
      <p className="text-sm font-medium">&copy; 2024 galatsy. All rights reserved.</p>
      <div className="mt-2">
        <Link to="/privacy-policy" className="text-gray-400 hover:text-gray-200 mx-2">
          Privacy Policy
        </Link>
        <Link to="/terms-of-service" className="text-gray-400 hover:text-gray-200 mx-2">
          Terms of Service
        </Link>
      </div>
    </footer>
  );
}