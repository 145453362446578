// File: src/components/TermsOfService.js
import React from 'react';

export default function TermsOfService() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white flex flex-col font-sans">
      <header className="py-12">
        <h1 className="text-4xl font-bold text-center">Terms of Service</h1>
      </header>
      <main className="flex-grow flex flex-col items-center justify-start p-4 overflow-x-hidden">
        <div className="w-full max-w-3xl space-y-6 text-gray-300">
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">1. Introduction</h2>
            <p>Welcome to <strong>Galatsy</strong> ("the App"). These Terms of Service ("Terms") govern your use of the App and any services provided by <strong>Galatsy Tech Solutions, Inc.</strong> ("we," "us," or "our"). By accessing or using the App, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use the App.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">2. Use of the App</h2>
            <p><strong>2.1 Eligibility:</strong> You must be at least 13 years old to use the App. By using the App, you represent that you meet this age requirement.</p>
            <p><strong>2.2 Account Registration:</strong> To access certain features of the App, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to keep your account information up to date.</p>
            <p><strong>2.3 Account Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. If you suspect unauthorized use of your account, you must notify us immediately.</p>
            <p><strong>2.4 Prohibited Activities:</strong> You agree not to engage in any activity that violates any applicable laws or regulations, involves the use of the App for fraudulent or malicious purposes, interferes with or disrupts the functioning of the App, or involves the transmission of viruses, malware, or any other harmful code.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">3. User Content</h2>
            <p><strong>3.1 Ownership:</strong> You retain ownership of any content you submit, post, or display on or through the App ("User Content"). By submitting User Content, you grant us a worldwide, non-exclusive, royalty-free, transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the User Content in connection with the App.</p>
            <p><strong>3.2 Prohibited Content:</strong> You agree not to submit User Content that is unlawful, harmful, or otherwise objectionable, infringes on the intellectual property rights of others, or contains personal information of others without their consent.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">4. Privacy</h2>
            <p>Our Privacy Policy, which is available at <a href="/privacy-policy" className="text-blue-400 hover:underline">https://galatsy.com/privacy-policy</a>, describes how we collect, use, and disclose information about you when you use the App. By using the App, you consent to our collection and use of your information as described in the Privacy Policy.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">5. Payment and Subscriptions</h2>
            <p><strong>5.1 Fees:</strong> Certain features of the App may require payment of fees. All fees are stated in <strong>USD</strong> and are non-refundable except as required by law.</p>
            <p><strong>5.2 Subscription Services:</strong> The App may offer subscription services. By purchasing a subscription, you agree to pay the applicable fees and to the automatic renewal of your subscription unless you cancel it before the renewal date.</p>
            <p><strong>5.3 Payment Information:</strong> You authorize us to charge your chosen payment method for the applicable fees. You are responsible for providing accurate payment information and keeping it up to date.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">6. Intellectual Property</h2>
            <p><strong>6.1 Ownership:</strong> We own all rights, title, and interest in and to the App, including all intellectual property rights. You may not use our trademarks, logos, or other proprietary materials without our prior written consent.</p>
            <p><strong>6.2 License:</strong> We grant you a limited, non-exclusive, non-transferable license to use the App for your personal, non-commercial use. This license is subject to these Terms and does not grant you any ownership rights.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">7. Termination</h2>
            <p><strong>7.1 Termination by You:</strong> You may terminate your account at any time by following the instructions in the App.</p>
            <p><strong>7.2 Termination by Us:</strong> We reserve the right to suspend or terminate your access to the App at any time, with or without cause, and without notice.</p>
            <p><strong>7.3 Effect of Termination:</strong> Upon termination, your right to use the App will immediately cease, and any data associated with your account may be deleted.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">8. Disclaimers and Limitation of Liability</h2>
            <p><strong>8.1 Disclaimers:</strong> The App is provided "as is" and "as available" without warranties of any kind, either express or implied. We do not warrant that the App will be uninterrupted or error-free.</p>
            <p><strong>8.2 Limitation of Liability:</strong> To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of the App.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">9. Indemnification</h2>
            <p>You agree to indemnify and hold us harmless from any claims, damages, losses, liabilities, costs, and expenses (including attorneys' fees) arising out of or related to your use of the App, your violation of these Terms, or your violation of any rights of another.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">10. Governing Law</h2>
            <p>These Terms shall be governed by and construed in accordance with the laws of <strong>California, USA</strong>, without regard to its conflict of law principles.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">11. Dispute Resolution</h2>
            <p><strong>11.1 Informal Resolution:</strong> Before filing a claim, you agree to attempt to resolve the dispute informally by contacting us at <a href="mailto:support@galatsy.com" className="text-blue-400 hover:underline">support@galatsy.com</a>.</p>
            <p><strong>11.2 Arbitration:</strong> If the dispute is not resolved informally, it shall be resolved by binding arbitration in accordance with the rules of the <strong>American Arbitration Association (AAA)</strong>.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">12. Changes to These Terms</h2>
            <p>We may update these Terms from time to time. If we make material changes, we will notify you by updating the date at the top of these Terms and providing notice through the App or via email.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">13. Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at <a href="mailto:support@galatsy.com" className="text-blue-400 hover:underline">support@galatsy.com</a>.</p>
          </section>
        </div>
      </main>
    </div>
  );
}