import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function PricingCard({ title, price, subPrice, features, highlighted, callToAction }) {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (callToAction === "Buy Now") {
      navigate('/subscription');
    }
    // You can add other conditions here for different call-to-actions if needed
  };

  return (
    <div className={`bg-gray-700 rounded-lg shadow-lg p-8 transition duration-300 hover:shadow-xl ${highlighted ? 'relative' : ''}`}>
      {highlighted && (
        <div className="absolute top-4 right-4 bg-black text-white text-xs font-semibold px-3 py-1 rounded-full">
          Most Popular
        </div>
      )}
      <h3 className="text-2xl font-bold text-white mb-4">{title}</h3>
      <p className="text-lg text-gray-300 mb-2">{price}</p>
      {subPrice && <p className="text-sm text-gray-400 mb-6">{subPrice}</p>}
      <ul className="text-gray-300 list-disc pl-6 space-y-2">
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <button 
        onClick={handleButtonClick}
        className={`${highlighted ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-blue-500 hover:bg-blue-600'} text-white font-semibold py-2 px-4 rounded mt-6 w-full transition duration-300`}
      >
        {callToAction}
      </button>
    </div>
  );
}