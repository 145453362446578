import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import LandingPage from './sections/LandingPage';
import BlogPage from './sections/BlogPage';
import TermsOfService from './sections/TermsOfService';
import PrivacyPolicy from './sections/PrivacyPolicy';
import SubscriptionPage from './sections/SubscriptionPage';

// Replace 'your_stripe_publishable_key' with your actual Stripe publishable key
const stripePublishableKey = 'pk_test_51PsNiLD2r2b47bRRlc4qvDRiFA8REYwIFCYDdsCMtgL8b8JtAHpEfXqoYKikKMDIs79OKkUUOzBoTPXMkzSq2k9N00joKYKtii';
const stripePromise = loadStripe(stripePublishableKey);

function App() {
  return (
    <Router>
      <Elements stripe={stripePromise}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/subscription" element={<SubscriptionPage />} />
          {/* Add more routes as needed */} 
        </Routes>
      </Elements>
    </Router>
  );
}

export default App;