import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ReactMarkdown from "react-markdown";

// Import the Markdown content
import maximizingProductivityContent from "./maximizing-productivity-post.md";
import powerOfPortfolioContent from "./power-of-portfolio.md";
import clientCollabarationContent from "./client-collaboration-strategy.md";
import valueOfTime from "./value-of-time-post.md";

const blogPosts = [
  {
    id: 1,
    title:
      "Building and Retaining Your Beauty Industry Clientele: The Key to Success",
    excerpt:
      "Learn how to leverage Galatsy's features to boost your professional productivity.",
    date: "May 5, 2024",
    contentFile: maximizingProductivityContent,
  },
  {
    id: 2,
    title:
      "The Power of a Personalized Portfolio: Elevating Your Barbering Business",
    excerpt:
      "Discover why having a well-crafted online portfolio is crucial in today's digital landscape.",
    date: "May 15, 2024",
    contentFile: powerOfPortfolioContent,
  },
  {
    id: 3,
    title: "Effective Client Collaboration Strategies",
    excerpt:
      "Explore best practices for seamless client collaboration using Galatsy's tools.",
    date: "May 20, 2024",
    contentFile: clientCollabarationContent,
  },
  {
    id: 4,
    title: "The Power of Time in the Service and Wellness Industry",
    excerpt:
      "Time is your most valuable asset. It's not just about managing your schedule...",
    date: "Jun 10, 2024",
    contentFile: valueOfTime,
  },
];

const BlogPost = ({ title, excerpt, date, onReadMore }) => (
  <div className="bg-gray-800 p-6 rounded-lg mb-6">
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p className="text-gray-300 mb-4">{excerpt}</p>
    <p className="text-sm text-gray-400">{date}</p>
    <button
      className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300"
      onClick={onReadMore}
    >
      Read More
    </button>
  </div>
);

const FullBlogPost = ({ title, date, content, onBack }) => (
  <div className="p-6 rounded-lg mb-6">
    <button
      className="mb-4 bg-gray-600 hover:bg-gray-700 text-white font-semibold py-2 px-4 rounded transition duration-300"
      onClick={onBack}
    >
      Back to Blog List
    </button>
    <h2 className="text-3xl font-bold mb-4">{title}</h2>
    <p className="text-sm text-gray-400 mb-6">{date}</p>
    <div className="prose prose-invert max-w-none">
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  </div>
);

export default function BlogPage() {
  const [selectedPost, setSelectedPost] = useState(null);
  const [postContent, setPostContent] = useState("");

  const handleReadMore = async (post) => {
    setSelectedPost(post);
    if (post.contentFile) {
      const content = await fetch(post.contentFile).then((res) => res.text());
      setPostContent(content);
    } else {
      setPostContent("Content not available.");
    }
  };

  const handleBack = () => {
    setSelectedPost(null);
    setPostContent("");
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white flex flex-col font-sans">
      <Header activeSection="blog" />
      <main className="flex-grow flex flex-col items-center justify-center p-4 overflow-x-hidden">
        <div className="w-full max-w-3xl">
          {selectedPost ? (
            <FullBlogPost
              {...selectedPost}
              content={postContent}
              onBack={handleBack}
            />
          ) : (
            <>
              <h1 className="text-4xl font-bold mb-8 text-center">
                Galatsy Blog
              </h1>
              {[...blogPosts].reverse().map((post) => (
                <BlogPost
                  key={post.id}
                  {...post}
                  onReadMore={() => handleReadMore(post)}
                />
              ))}
            </>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
}
