import React, { forwardRef } from 'react';

const Feature = ({ title, description, imageSrc, imageAlt, reverse }) => (
  <div className={`flex flex-col ${reverse ? 'md:flex-row-reverse' : 'md:flex-row'} items-center mb-16 last:mb-0`}>
    <div className="w-full md:w-1/2 mb-6 md:mb-0">
      <h3 className="text-2xl font-bold mb-4">{title}</h3>
      <p className="text-gray-300">{description}</p>
    </div>
    <div className="w-full md:w-1/2 md:px-8 flex justify-center">
      <img src={imageSrc} alt={imageAlt} className="w-full h-auto rounded-lg shadow-lg max-w-xs" />
    </div>
  </div>
);

const Features = forwardRef((props, ref) => {
  const features = [
    {
      title: "Create Your Portfolio of Services",
      description: "Showcase your skills and services in a customized portfolio that speaks to your clients' needs. Our intuitive interface allows you to create stunning portfolios that highlight your best work and attract potential clients.",
      imageSrc: "/images/portfolio_content.png",
      imageAlt: "Portfolio creation interface"
    },
    {
      title: "Share Results of Your Work",
      description: "Keep your clients updated with detailed reports and visual presentations of your work. Our platform enables you to create professional, interactive reports that demonstrate the value you bring to your clients' projects.",
      imageSrc: "/images/share.png",
      imageAlt: "Work results dashboard"
    },
    {
      title: "Collaborate with Customers",
      description: "Create a workspace to collaborate seamlessly with your customers. Share ideas, and feedback in real-time. Our collaboration tools make it easy to communicate effectively and keep everyone on the same page throughout the project.",
      imageSrc: "/images/update_notes.png",
      imageAlt: "Collaboration workspace"
    },
    {
      title: "Maintain Service History and Portfolio",
      description: "Efficiently maintain a detailed history of services provided, along with a well-curated portfolio. This setup allows you easily provide consultation and review past haircuts you did, facilitating a deeper understanding of the range and quality of your services.",
      imageSrc: "/images/my_projects.png", // Adjusted path if needed
      imageAlt: "Maintained service history and client portfolio"
    }
  ];

  return (
    <section ref={ref} id="features" className="flex flex-col justify-center items-center min-h-screen py-16 px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl sm:text-4xl font-bold mb-12 sm:mb-16 text-center">Features</h2>
      <div className="w-full max-w-6xl">
        {features.map((feature, index) => (
          <Feature
            key={index}
            title={feature.title}
            description={feature.description}
            imageSrc={feature.imageSrc}
            imageAlt={feature.imageAlt}
            reverse={index % 2 !== 0}
          />
        ))}
      </div>
    </section>
  );
});

export default Features;

