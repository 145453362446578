// File: src/components/LandingPage/Pricing.js
import React, { forwardRef } from 'react';
import PricingCard from './PricingCard';

const Pricing = forwardRef((props, ref) => {
  return (
    <section ref={ref} id="pricing" className="flex flex-col justify-center items-center min-h-screen py-16">
      <h2 className="text-4xl font-bold text-white mb-10">Choose Your Plan</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-5xl">
        <PricingCard
          title="Essentials"
          price="Free"
          features={[
            "Limited template creation",
            "Limited image storage",
            "Standard analytics",
            "Community support"
          ]}
          callToAction={"Get Started"}
        />
        <PricingCard
          title="Professional"
          price="$9.99/month"
          subPrice="or $99.99/year"
          features={[
            "All features of Essentials",
            "Access to more templates",
            "AI Professional note taking",
            "Enhanced image storage",
            "Advanced analytics",
            "Email support"
          ]}
          callToAction={"Buy Now"}
          highlighted={true}
        />
        <PricingCard
          title="Enterprise "
          price=""
          subPrice=""
          features={[
            "All features of Professional",
            "Unlimited Templates",
            "Increased Storage for Images and Photos",
            "Access to Video Storage",
            "Custom Branding",
            "Multi-User Access",
            "Flexible Export Options",
            "Priority Support"
          ]}
          callToAction={"Contact Sales"}
        />
      </div>
    </section>
  );
});

export default Pricing;
