import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import states from '../elements/states';
import SignUpProcessor from '../hooks/SignUpProcessor';
import SignInProcessor from '../hooks/SignInProcessor'; // Import SignInProcessor
import { AlertCircle, CheckCircle } from 'lucide-react';

const API_ENDPOINT = 'https://your-api-endpoint.com/create-subscription';

const containerMinHeight = "600px";

const SubscriptionPage = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [step, setStep] = useState(1);
  const [billingCycle, setBillingCycle] = useState('yearly');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('United States');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSignIn, setIsSignIn] = useState(false); // New state to toggle between Sign Up and Sign In

  const register = SignUpProcessor({ setIsLoading: setLoading, isLoading: loading, setError });
  const login = SignInProcessor({ setIsLoading: setLoading, isLoading: loading, setError }); // Initialize SignInProcessor

  const yearlyPrice = 99.99;
  const monthlyPrice = 9.99;
  const yearlyDiscount = 17;

  const handleRegister = async () => {
    const [firstName, lastName] = name.split(' ');
    const credentials = {
      email,
      password,
      personal_profile: {
        first_name: firstName || '',
        last_name: lastName || '',
      }
    };

    const result = await register(credentials);
    console.log(result)

    if (result) {
      setStep(2); // Proceed to the payment step if registration is successful
    }
  };

  const handleSignIn = async () => {
    const credentials = {
      identifier:email,
      password:password,
    };

    const result = await login(credentials);
    console.log(result)

    if (result) {
      setStep(2); // Proceed to the payment step if login is successful
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      setError("Stripe has not been initialized.");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name,
          email,
          address: {
            line1: addressLine1,
            line2: addressLine2,
            city: city,
            postal_code: zip,
            state: state,
            country: 'US',
          },
        },
      });

      if (stripeError) {
        throw new Error(stripeError.message);
      }

      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          email,
          name,
          address: {
            line1: addressLine1,
            line2: addressLine2,
            city,
            zip,
            state,
            country,
          },
          billingCycle,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'An error occurred while processing your subscription.');
      }

      alert('Subscription successful! Welcome to Galatsy Pro!');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const renderSignUpForm = () => (
    <>
      <h2 className="text-2xl font-bold mb-6">Create Your Account</h2>
      <div className="mb-4">
        <label htmlFor="email" className="block text-sm font-medium mb-1">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-3 py-2 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="password" className="block text-sm font-medium mb-1">Password</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-3 py-2 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <div className="mb-6">
        <label htmlFor="name" className="block text-sm font-medium mb-1">Full Name</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full px-3 py-2 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <div className="space-y-4">
        <button
          onClick={handleRegister}
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors duration-300 flex items-center justify-center"
          disabled={loading}
        >
          {loading ? 'Creating Account...' : 'Next Step'}
          <ChevronRight className="ml-2" />
        </button>
        <div className="flex items-center justify-center space-x-2">
          <span className="text-gray-400">Already have an account?</span>
          <button
            onClick={() => setIsSignIn(true)} // Toggle to Sign In form
            className="text-white py-2 px-4 rounded-md hover:bg-gray-700 transition-colors duration-300"
          >
            Sign In
          </button>
        </div>
      </div>
    </>
  );

  const renderSignInForm = () => (
    <>
      <h2 className="text-2xl font-bold mb-6">Sign In</h2>
      <div className="mb-4">
        <label htmlFor="email" className="block text-sm font-medium mb-1">Email</label>
        <input
          type="identifier"
          id="identifier"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-3 py-2 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="password" className="block text-sm font-medium mb-1">Password</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-3 py-2 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <div className="space-y-4">
        <button
          onClick={handleSignIn}
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors duration-300 flex items-center justify-center"
          disabled={loading}
        >
          {loading ? 'Signing In...' : 'Next Step'}
          <ChevronRight className="ml-2" />
        </button>
        <div className="flex items-center justify-center space-x-2">
          <span className="text-gray-400">Don't have an account?</span>
          <button
            onClick={() => setIsSignIn(false)} // Toggle back to Sign Up form
            className="text-white py-2 px-4 rounded-md hover:bg-gray-700 transition-colors duration-300"
          >
            Create Account
          </button>
        </div>
      </div>
    </>
  );

  const renderStepOne = () => (
    <div style={{ minHeight: containerMinHeight }}>
      {isSignIn ? renderSignInForm() : renderSignUpForm()}
    </div>
  );

  const renderStepTwo = () => (
    <div style={{ minHeight: containerMinHeight }}>
      <h2 className="text-2xl font-bold mb-6">Choose Your Plan</h2>
      <div className="mb-6">
        <div className="flex gap-4">
          <label className={`flex-1 p-4 border rounded-lg cursor-pointer transition-all ${billingCycle === 'yearly' ? 'border-blue-500 bg-blue-500 bg-opacity-10' : 'border-gray-600'}`}>
            <input
              type="radio"
              value="yearly"
              checked={billingCycle === 'yearly'}
              onChange={() => setBillingCycle('yearly')}
              className="sr-only"
            />
            <div>
              <div className="font-medium">Yearly</div>
              <div className="text-sm text-gray-300">${yearlyPrice.toFixed(2)}/year</div>
              <div className="text-xs text-blue-400 mt-1">Save {yearlyDiscount}%</div>
            </div>
          </label>
          <label className={`flex-1 p-4 border rounded-lg cursor-pointer transition-all ${billingCycle === 'monthly' ? 'border-blue-500 bg-blue-500 bg-opacity-10' : 'border-gray-600'}`}>
            <input
              type="radio"
              value="monthly"
              checked={billingCycle === 'monthly'}
              onChange={() => setBillingCycle('monthly')}
              className="sr-only"
            />
            <div>
              <div className="font-medium">Monthly</div>
              <div className="text-sm text-gray-300">${monthlyPrice.toFixed(2)}/month</div>
            </div>
          </label>
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-1" htmlFor="cardholder-name">
          Cardholder Name
        </label>
        <input
          type="text"
          id="cardholder-name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full px-3 py-2 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>

      <div className="mb-6">
        <label className="block text-sm font-medium mb-1">Card Information</label>
        <div className="bg-gray-700 rounded-md p-3">
          <CardElement options={{ style: { base: { fontSize: '16px', color: 'white' } } }} />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium mb-1" htmlFor="address-line1">
          Billing Address
        </label>
        <div className="mb-2">
          <select
            id="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="w-full px-3 py-2 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="United States">United States</option>
            {/* <option value="Canada">Canada</option> */}
          </select>
        </div>
        <div className="mb-2">
          <input
            type="text"
            id="address-line1"
            value={addressLine1}
            onChange={(e) => setAddressLine1(e.target.value)}
            placeholder="Address line 1"
            className="w-full px-3 py-2 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="mb-2">
          <input
            type="text"
            id="address-line2"
            value={addressLine2}
            onChange={(e) => setAddressLine2(e.target.value)}
            placeholder="Address line 2"
            className="w-full px-3 py-2 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-2 flex space-x-2">
          <input
            type="text"
            id="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="City"
            className="w-1/2 px-3 py-2 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <input
            type="text"
            id="zip"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            placeholder="ZIP"
            className="w-1/2 px-3 py-2 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        <div className="mb-6">
          <select
            id="state"
            value={state}
            onChange={(e) => setState(e.target.value)}
            className="w-full px-3 py-2 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select a State</option>
            {states.map((state) => (
              <option key={state.value} value={state.value}>
                {state.label}
              </option>
            ))}
          </select>
        </div>

      </div>

      <div className="flex items-center justify-between mb-6">
        <button
          onClick={() => setStep(1)}
          className="text-blue-400 hover:text-blue-300 transition-colors duration-300 flex items-center"
        >
          <ChevronLeft className="mr-2" /> Back
        </button>
        <button
          onClick={handleSubmit}
          className="bg-green-600 text-white py-2 px-6 rounded-md hover:bg-green-700 transition-colors duration-300 flex items-center justify-center disabled:opacity-50"
          disabled={!stripe || loading}
        >
          {loading ? 'Processing...' : `Subscribe Now - $${billingCycle === 'yearly' ? yearlyPrice.toFixed(2) : monthlyPrice.toFixed(2)}/${billingCycle === 'yearly' ? 'year' : 'month'}`}
        </button>
      </div>

      {error && <div className="text-red-500 mb-4">{error}</div>}
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white flex flex-col font-sans">
      <header className="w-full p-4 bg-black bg-opacity-50">
        <Link
          to="/"
          className="text-2xl font-extralight text-white"
          style={{ fontFamily: "Mont-ExtraLightDEMO, sans-serif" }}
        >
          galatsy
        </Link>
      </header>
      <main className="flex-grow flex items-center justify-center p-4">
        <div className="w-full max-w-4xl bg-gray-800 rounded-lg shadow-xl overflow-hidden">
          <div className="md:flex">
            <div className="w-full md:w-1/2 p-6 md:p-8">
              <h1 className="text-3xl font-bold mb-6">Subscribe to Galatsy Pro</h1>
              <div className="mb-6">
                <div className="flex mb-4">
                  {[1, 2].map((s) => (
                    <div key={s} className={`flex-1 ${s === 1 ? 'text-left' : 'text-right'}`}>
                      <span className={`inline-flex items-center justify-center w-8 h-8 rounded-full ${s === step ? 'bg-blue-600' : 'bg-gray-600'}`}>
                        {s}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="h-2 bg-gray-600 rounded-full">
                  <div
                    className="h-full bg-blue-600 rounded-full transition-all duration-300"
                    style={{ width: `${(step / 2) * 100}%` }}
                  ></div>
                </div>
              </div>
              {step === 1 ? renderStepOne() : renderStepTwo()}
            </div>
            <div className="w-full md:w-1/2 bg-gray-900 p-6 md:p-8">
              <h2 className="text-2xl font-bold mb-6">Galatsy Pro vs Free</h2>
              <div className="mb-6">
                <div className="flex justify-between mb-2">
                  <span className="w-1/3"></span>
                  <span className="w-1/3 text-center">Free</span>
                  <span className="w-1/3 text-center">Pro</span>
                </div>
                {[
                  "AI-powered features",
                  "Template customization",
                  "Customer support",
                  "New feature access"
                ].map((feature, index) => (
                  <div key={index} className="flex justify-between mb-2 items-center">
                    <span className="w-1/3">{feature}</span>
                    <span className="w-1/3 flex justify-center items-center">
                      {index === 1 ? (
                        "Limited"
                      ) : (
                        <AlertCircle className="text-gray-500 w-5 h-5" />
                      )}
                    </span>
                    <span className="w-1/3 flex justify-center items-center">
                      {index === 1 ? (
                        "Unlimited"
                      ) : (
                        <CheckCircle className="text-green-500 w-5 h-5" />
                      )}
                    </span>
                  </div>
                ))}
              </div>

              <div className="mb-6 p-4 bg-blue-900 bg-opacity-50 rounded-lg">
                <h3 className="text-lg font-semibold mb-2">100% Satisfaction Guarantee</h3>
                <p className="text-sm text-gray-300">Try Galatsy Pro risk-free. If you're not completely satisfied, we offer a full refund within the first 30 days.</p>
              </div>
              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-2">What our users say</h3>
                <div className="bg-gray-800 p-4 rounded-lg">
                  <p className="text-sm italic mb-2">"Galatsy Pro has revolutionized my workflow. The AI features are simply amazing!"</p>
                  <p className="text-xs text-gray-400">- Daniel Pink, Designer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SubscriptionPage;
