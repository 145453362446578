// File: src/components/PrivacyPolicy.js
import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white flex flex-col font-sans">
      <header className="py-12">
        <h1 className="text-4xl font-bold text-center">Privacy Policy</h1>
      </header>
      <main className="flex-grow flex flex-col items-center justify-start p-4 overflow-x-hidden">
        <div className="w-full max-w-3xl space-y-6 text-gray-300">
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">1. Introduction</h2>
            <p>Welcome to <strong>Galatsy</strong> ("the App"), operated by <strong>Galatsy Tech Solutions, Inc.</strong> ("we," "us," or "our"). We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use the App. If you do not agree with the terms of this Privacy Policy, please do not access the App.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">2. Information We Collect</h2>
            <p><strong>2.1 Personal Data:</strong> Personally identifiable information, such as your name, email address, and demographic information, that you voluntarily give to us when you create an account or participate in various activities related to the App, such as subscribing to a newsletter or using our services.</p>
            <p><strong>2.2 Derivative Data:</strong> Information our servers automatically collect when you access the App, such as your IP address, browser type, operating system, access times, and the pages you have viewed directly before and after accessing the App.</p>
            <p><strong>2.3 Financial Data:</strong> Financial information, such as your payment method (e.g., credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the App. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor, [Payment Processor Name], and you are encouraged to review their privacy policy and contact them directly for responses to your questions.</p>
            <p><strong>2.4 Mobile Device Data:</strong> Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you access the App from a mobile device.</p>
            <p><strong>2.5 Third-Party Data:</strong> Information from third parties, such as social media sites, that you voluntarily link to your account, and information from other third parties when you use their services in connection with our App.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">3. How We Use Your Information</h2>
            <p>We use the information we collect in various ways, including to:</p>
            <ul className="list-disc list-inside">
              <li>Provide and manage services, such as delivering the products and services you request and managing your account.</li>
              <li>Improve the App by analyzing usage trends and preferences.</li>
              <li>Communicate with you by sending updates, marketing and promotional materials, and other information that may be of interest to you.</li>
              <li>Process payments and manage your orders, including billing and shipping.</li>
              <li>Comply with legal obligations and enforce our terms, conditions, and policies.</li>
            </ul>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">4. Disclosure of Your Information</h2>
            <p>We may share information we have collected about you in certain situations:</p>
            <ul className="list-disc list-inside">
              <li><strong>By Law or to Protect Rights:</strong> We may share your information to comply with applicable laws and regulations, to respond to a subpoena, court order, or legal process, or to protect our rights, property, or safety or that of others.</li>
              <li><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
              <li><strong>Third-Party Service Providers:</strong> We may share your information with third parties that perform services for us or on our behalf, such as payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
              <li><strong>Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy.</li>
              <li><strong>With Your Consent:</strong> We may disclose your personal information for any other purpose with your consent.</li>
            </ul>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">5. Tracking Technologies</h2>
            <p><strong>5.1 Cookies and Web Beacons:</strong> We may use cookies, web beacons, tracking pixels, and other tracking technologies on the App to help customize the App and improve your experience. Most browsers are set to accept cookies by default. You can remove or reject cookies, but this could affect the availability and functionality of the App.</p>
            <p><strong>5.2 Third-Party Analytics:</strong> We may use third-party analytics services, such as Google Analytics, to evaluate your use of the App, compile reports on activity, collect demographic data, analyze performance metrics, and collect other information.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">6. Security of Your Information</h2>
            <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other types of misuse.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">7. Data Retention</h2>
            <p>We will retain your personal information only for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">8. Your Rights and Choices</h2>
            <p><strong>8.1 Access to Your Information:</strong> You have the right to access, correct, or delete the personal information that we hold about you. You can do so by contacting us at the email provided below.</p>
            <p><strong>8.2 Opt-Out of Marketing Communications:</strong> You may opt out of receiving marketing communications from us by following the unsubscribe instructions provided in the emails.</p>
            <p><strong>8.3 Do Not Track:</strong> Most web browsers and some mobile operating systems include a Do Not Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">9. Children’s Privacy</h2>
            <p>We do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will delete that information as quickly as possible. If you believe we might have any information from or about a child under 13, please contact us at the email provided below.</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">10. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by updating the "Last Updated" date at the top of this Privacy Policy and, in some cases, we may provide additional notice (such as adding a statement to our homepage or sending you a notification).</p>
          </section>
          <section>
            <h2 className="text-2xl font-semibold text-gray-200">11. Contact Us</h2>
            <p>If you have questions or comments about this Privacy Policy, please contact us at:</p>
            <p><strong>Galatsy Tech Solutions, Inc.</strong><br />
              Email: <a href="mailto:support@galatsy.com" className="text-blue-400 hover:underline">support@galatsy.com</a><br /></p>
          </section>
        </div>
      </main>
    </div>
  );
}