const SignUpProcessor = ({ setIsLoading, isLoading, setError }) => {
    // Get the API URL and API key from the environment variables
    const REGISTER_API_ENDPOINT = `${process.env.REACT_APP_API_BASE_URL}/register/`;
    const API_KEY = process.env.REACT_APP_API_KEY;

    const register = async (credentials) => {
        if (isLoading) return;
        setIsLoading(true);

        // Create the request headers object
        const headers = {
            'Content-Type': 'application/json',
            'X-API-Key': API_KEY,
        };

        console.log(credentials)
        
        try {
            const response = await fetch(REGISTER_API_ENDPOINT, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(credentials),
            });

            console.log('Response:', response);

            // Check if the response is okay
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();

            if (!data.token) {
                const key = Object.keys(data)[0];
                const error = new Error(data[key][0]);
                error.field = key;
                throw error;
            } else {
                return data;
            }
        } catch (err) {
            setError(err);
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    return register;
};

export default SignUpProcessor;
