import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Hero from './Hero';
import Features from './Features';
import Pricing from './Pricing';
import DownloadSection from './DownloadSection';
import Footer from './Footer';
import usePageTracking from '../hooks/usePageTracking';

export default function LandingPage() {
  usePageTracking();
  const [activeSection] = useState('');
  const location = useLocation();
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);

  const scrollToSection = (targetId) => {
    const targetRef =
      targetId === 'features'
        ? featuresRef
        : targetId === 'pricing'
        ? pricingRef
        : null;

    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (location.hash) {
      const section = location.hash.slice(1);
      scrollToSection(section);
    }
  }, [location]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white flex flex-col font-sans">
      <Header activeSection={activeSection} scrollToSection={scrollToSection} />
      <main className="flex-grow flex flex-col items-center justify-center p-4 overflow-x-hidden">
        <div className="w-full max-w-7xl">
          <Hero />
          <Features ref={featuresRef} />
          <Pricing ref={pricingRef} />
        </div>
      </main>
      <DownloadSection />
      <Footer />
    </div>
  );
}