import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "lucide-react";
import Modal from "./Modal"; // Ensure this import is correct

// ModalForm Component
function ModalForm({ closeModal }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  // Get the API URL and API key from the environment variables
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/early-adopters/`;
  // const apiUrl = `/api/early-adopters/`; // Base URL is automatically handled by the proxy
  const apiKey = process.env.REACT_APP_API_KEY;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': apiKey,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFirstName('');
        setLastName('');
        setEmail('');
        closeModal();
        alert('Thank you for joining the waitlist!');
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to send data');
      }
    } catch (error) {
      console.error('Error saving form data:', error);
      alert('Thank you, we will reach out soon.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex space-x-4">
        <input
          type="text"
          placeholder="First name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className="flex-1 border rounded px-.8 py-2"
          required
        />
        <input
          type="text"
          placeholder="Last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          className="flex-1 border rounded px-.8 py-2"
          required
        />
      </div>
      <input
        type="email"
        placeholder="Email address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full border rounded px-3 py-2"
        required
      />
      <button
        type="submit"
        className="w-full bg-black text-white font-bold py-2 px-4 rounded"
      >
        Join Waitlist
      </button>
    </form>
  );
}

export default function Header({ activeSection, scrollToSection }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  const handleNavigation = (item) => {
    setIsMenuOpen(false);
    if (location.pathname === "/" && scrollToSection) {
      scrollToSection(item);
    }
  };

  return (
    <header className="bg-gray-900 py-4 px-6 shadow-md sticky top-0 z-10">
      <nav className="max-w-7xl mx-auto flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <Link
            to="/"
            className="text-2xl font-extralight text-white"
            style={{ fontFamily: "Mont-ExtraLightDEMO, sans-serif" }}
          >
            galatsy
          </Link>
          <div className="flex items-center">
            <button
              onClick={() => setIsModalOpen(true)}
              className="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-2 px-4 rounded mr-4"
            >
              Get Early Access
            </button>
            <button
              className="lg:hidden text-white focus:outline-none"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <Menu size={24} />
            </button>
          </div>
        </div>
        <div
          className={`${isMenuOpen ? "flex" : "hidden"
            } lg:flex flex-col lg:flex-row justify-start items-start lg:items-center space-y-2 lg:space-y-0 lg:space-x-4`}
        >
          {["features", "pricing", "blog"].map((item) => (
            <Link
              key={item}
              to={item === "blog" ? "/blog" : `/${item === "features" ? "" : `#${item}`}`}
              className={`px-3 py-2 text-sm font-medium transition-colors duration-300 ${activeSection === item
                ? "text-white"
                : "text-gray-300 hover:text-white"
                }`}
              onClick={(e) => {
                if (location.pathname === "/" && item !== "blog") {
                  e.preventDefault();
                  handleNavigation(item);
                }
                setIsMenuOpen(false);
              }}
            >
              {item.charAt(0).toUpperCase() + item.slice(1)}
            </Link>
          ))}
        </div>
      </nav>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="text-black">
          <h2 className="text-2xl font-bold mb-4">Get Early Access</h2>
          <p className="mb-4">Upgrade your service!</p>
          <ModalForm closeModal={() => setIsModalOpen(false)} />
        </div>
      </Modal>
    </header>
  );
}